import React, { useState } from "react";
import { login, autoLogin }  from '../service/account'
import { connect }  from 'react-redux'
function Login({loginSuccessCallback, dispatch}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const  _handleKeyPress = function(e) {
    if (e.key === 'Enter') {
      console.log('do validate');
      doLogin(username, password,dispatch)
    }
  }
  return (
    <div class="splash-container">
      <div class="card card-border-color card-border-color-primary">
        <div class="card-header">
          {/* <img
            class="logo-img"
            src="assets/img/logo-xx.png"
            alt="logo"
            width="102"
            height="27"
          /> */}
          <h3>ระบบออกใบกำกับภาษีเต็มรูปแบบ</h3>
          <span class="splash-description">
            Please enter your username and password.
          </span>
        </div>
        <div class="card-body">
          <div class="form-group">
          <label>Username</label>
            <input
              class="form-control"
              onChange={evt => setUsername(evt.target.value)}
              value={username}
              id="username"
              type="text"
              placeholder="Username"
              autocomplete="off"
              onKeyPress={_handleKeyPress}
              
            />
          </div>
          <div class="form-group">
          <label>Password</label>
            <input
              class="form-control"
              onChange={evt => setPassword(evt.target.value)}
              value={password}
              id="password"
              type="password"
              placeholder="Password"
              onKeyPress={_handleKeyPress}
              
            />
          </div>
          <div class="form-group login-submit">
            <a class="btn btn-primary btn-xl" data-dismiss="modal"  onClick={()=> doLogin(username, password,dispatch)}>
              Sign me in
            </a>
          </div>
        </div>
      </div>
      {/* <div class="splash-footer">
        <span>
          Don't have an account? <a href="pages-sign-up.html">Sign Up</a>
        </span>
      </div> */}
    </div>
  );
}


function doLogin(username, password, dispatch){
    return login(username, password).then((res)=>{
          
        console.log(res);
        window.localStorage.setItem('username',username )
        window.localStorage.setItem('password',password )

        dispatch ( autoLogin( ) )
        // loginSuccessCallback && loginSuccessCallback(true)
        // window.location.reload();
    }).catch((err)=>{
         
        window.alert(err)
    })
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch  
  }
}

export default connect(null, mapDispatchToProps)(Login)