import axios from "axios";

export const USER = "USER";
export const SHOP_INFO = "SHOP_INFO";
export const VIEW = "VIEW";
export const HOLDER = "HOLDER";

export const downloadPdfReceiptsList = (paramsQuery, options, cb) => {
  return (dispatch, getState) => {
    var URL =
      "https://bd8gco41te.execute-api.ap-southeast-1.amazonaws.com/dev/tax/taxReceiptsPdf";

    var params1 = { ...paramsQuery, ...options };
    axios
      .post(URL, params1)
      .then(res => {
        console.log(res);
        cb && cb(res.data);
      })
      .catch(err => {
        console.log(err);
        window.alert("ERROR " + err.toString());
        cb && cb();
      });
  };
};

// real repo in report service
export const downloadExportExcel = (paramsQuery, options, cb) => {
  return (dispatch, getState) => {
    var URL =
      "https://bd8gco41te.execute-api.ap-southeast-1.amazonaws.com/dev/tax/taxExportExcelFullVat";

    var params1 = { ...paramsQuery, ...options };
    axios
      .post(URL, params1)
      .then(res => {
        console.log(res);
        cb && cb(res.data);
      })
      .catch(err => {
        console.log(err); 
        window.alert("ERROR " + err.toString());
        cb && cb();
      });
  };
};

// tax invoices report excel
export const downloadTaxInvoiceReportExcel = (paramsQuery, options, cb) => {
  return (dispatch, getState) => {
    var URL =
      "https://us-central1-renew-tax-v1-niceloop.cloudfunctions.net/abbFullInvoicesReport";

    var params1 = { ...paramsQuery, ...options };
    axios
      .post(URL, params1)
      .then(res => {
        console.log(res);
        cb && cb(res.data);
      })
      .catch(err => {
        console.log(err);
        window.alert("ERROR " + err.toString());
        cb && cb();
      });
  };
};
